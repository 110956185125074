import React from 'react'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  PageHeader,
  Row,
  Skeleton,
  Space,
  Switch,
  Typography,
} from 'antd'
import { FileImageOutlined, SaveOutlined } from '@ant-design/icons'
import { STATIC_CONTENT_KEY, useStaticContent } from '@app/hooks/query/static-content'
import {
  ErrorBoundary,
  FormFileManagerSelect,
  ImagesGrid,
  ImageWithFallback,
  SiteLink,
} from '@app/shared'
import { IStaticContentLayout, IStaticContentResponse } from '@app/interfaces/static-content'
import { useMutation } from 'react-query'
import { StaticContentService } from '@app/api'
import {
  StaticFormLinks,
  StaticFormSocials,
  StaticLogo,
} from '@app/pages/static-elements-page/components'
import { queryClient } from '@app/index'
import { getStaticPageImagePath } from '@app/utils/getStaticPageImagePath'

interface props {}

const StaticElementsPage: React.FC<props> = () => {
  const [form] = Form.useForm()
  const { isLoading, isError, data } =
    useStaticContent<IStaticContentResponse<IStaticContentLayout>>('layout')
  const update = useMutation(StaticContentService.updateLayout, {
    onSuccess: () => {
      message.success('Данные успешно обновлены')
      queryClient.invalidateQueries([STATIC_CONTENT_KEY, 'layout'])
    },
    onError: () => {
      message.error('Ошибка обновления данных!')
    },
  })

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const onFinish = (data: IStaticContentLayout) => update.mutateAsync({ pageType: 'layout', data })

  const initialValues: IStaticContentLayout = {
    contacts: data?.data?.contacts ?? {
      address: '',
      email: '',
      number: '',
    },
    feedback: data?.data?.feedback ?? {
      image_mobile: '',
      image_pc: '',
    },
    footer_company_links: data?.data?.footer_company_links ?? [],
    header_links: data?.data?.header_links ?? [],
    footer_logo: data?.data?.footer_logo ?? '',
    header_logo: data?.data?.header_logo ?? '',
    ticker: data?.data?.ticker ?? {
      content: '',
      href: '',
      background: '',
      status: false,
      fixed: false,
    },
    socials: data?.data?.socials ?? [
      { title: '', url: '', slug: 'ok', active: true },
      { title: '', url: '', slug: 'telegram', active: true },
      { title: '', url: '', slug: 'vk', active: true },
      { title: '', url: '', slug: 'youtube', active: true },
      { title: '', url: '', slug: 'zen', active: true },
      { title: '', url: '', slug: 'twitter', active: false },
      { title: '', url: '', slug: 'instagram', active: false },
      { title: '', url: '', slug: 'facebook', active: false },
    ],
  }

  return (
    <React.Fragment>
      <PageHeader title={'Статические элементы'} extra={<SiteLink entityType={''} />} />

      <Form form={form} onFinish={onFinish} layout={'vertical'} initialValues={initialValues}>
        <Row gutter={[48, 24]}>
          <Col xl={12}>
            <StaticLogo src={data?.data?.header_logo} variant={'header'} />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={'header_logo'}
              label={'Логотип шапки'}
              initialPath={getStaticPageImagePath(data?.data?.header_logo)}
            />
            <Form.Item label={'Бегущая строка'}>
              <Form.Item name={['ticker', 'content']}>
                <Input.TextArea rows={6} placeholder={'Введите текст бегущей строки'} />
              </Form.Item>
              <Form.Item
                name={['ticker', 'href']}
                label={'Ссылка для перехода (Оставьте пустым, если переход не нужен)'}
              >
                <Input placeholder={'Ссылка для перехода'} />
              </Form.Item>
              <Row gutter={[16, 16]}>
                <Col flex={1}>
                  <FormFileManagerSelect
                    formInstance={form}
                    pathFieldName={['ticker', 'background']}
                    label={'Фоновое изображение'}
                    initialPath={getStaticPageImagePath(data?.data?.ticker.background)}
                  />
                </Col>
                <Col>
                  <Form.Item label={'Удалить фоновое изображение'}>
                    <Button
                      onClick={() => {
                        form.setFields([
                          {
                            name: ['ticker', 'background'],
                            value: '',
                          },
                        ])
                      }}
                      block
                      icon={<FileImageOutlined />}
                      type={'dashed'}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Space size={32}>
                <Form.Item name={['ticker', 'status']} valuePropName={'checked'}>
                  <Switch checkedChildren={'Включена'} unCheckedChildren={'Отключена'} />
                </Form.Item>
                <Form.Item name={['ticker', 'fixed']} valuePropName={'checked'}>
                  <Checkbox>Зафиксировать</Checkbox>
                </Form.Item>
              </Space>
            </Form.Item>

            <Form.Item label={'Ссылки шапки'}>
              <StaticFormLinks name={'header_links'} />
            </Form.Item>

            <Form.Item label={'Контактная информация'}>
              <Form.Item name={['contacts', 'number']} label={'Номер службы поддержки'}>
                <Input placeholder={'Введите номер службы поддержки'} />
              </Form.Item>
              <Form.Item name={['contacts', 'address']} label={'Адрес'}>
                <Input placeholder={'Введите адрес'} />
              </Form.Item>
              <Form.Item name={['contacts', 'email']} label={'Электронная почта'}>
                <Input placeholder={'Введите электронную почту'} />
              </Form.Item>
            </Form.Item>

            <Typography.Title level={4}>Обратная связь</Typography.Title>
            <ImageWithFallback src={data?.data?.feedback?.image_mobile} height={75} preview />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={['feedback', 'image_mobile']}
              label={'Изображение (смартфоны)'}
              initialPath={getStaticPageImagePath(data?.data?.feedback?.image_mobile)}
            />
            <ImageWithFallback src={data?.data?.feedback?.image_pc} height={75} preview />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={['feedback', 'image_pc']}
              label={'Изображение (планшеты)'}
              initialPath={getStaticPageImagePath(data?.data?.feedback?.image_pc)}
            />
          </Col>

          <Col xl={12}>
            <StaticLogo src={data?.data?.footer_logo} variant={'footer'} />
            <FormFileManagerSelect
              formInstance={form}
              pathFieldName={'footer_logo'}
              label={'Логотип футера'}
              initialPath={getStaticPageImagePath(data?.data?.footer_logo)}
            />

            <Form.Item label={'Ссылки блока "Компания"'}>
              <StaticFormLinks name={'footer_company_links'} />
            </Form.Item>

            <Form.Item label={'Социальные сети'}>
              <StaticFormSocials />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item>
              <Button
                type={'primary'}
                htmlType={'submit'}
                icon={<SaveOutlined />}
                loading={update.isLoading}
                disabled={update.isLoading}
              >
                Сохранить
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default StaticElementsPage
