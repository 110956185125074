import React from 'react'
import { useBrand } from '@app/hooks/query/brands'
import { useParams } from 'react-router-dom'
import { Card, Skeleton, Typography } from 'antd'
import {
  ErrorBoundary,
  ImageWithFallback,
  PageInfo,
  SecondaryText,
  SpaceWithDivider,
  StatusTag,
} from '@app/shared'

const {Title} = Typography

interface props {}

const BrandPage: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { isError, isLoading, data } = useBrand(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const { title, active, logo } = data!

  return (
    <React.Fragment>
      <PageInfo title={title} />
      <Card title={'Общая информация'} extra={[<StatusTag status={active} />]}>
        <ImageWithFallback src={logo} height={50} />
        <Title level={2}>{title}</Title>
        <SpaceWithDivider>
          <SecondaryText>ID: {id}</SecondaryText>
        </SpaceWithDivider>
      </Card>
    </React.Fragment>
  )
}

export default BrandPage
